@tailwind base;
@tailwind components;
@tailwind utilities;

.d-flex{
  font-family: "Roboto";
}
.App{

  margin: 0 auto;
  background-color: green;
}

.fs-4{
  font-size: 0.1rem;
  line-height: 1rem;

  font-weight: 300;
  color: grey;

}

.fs-5{

  font-weight: 550;
  color: black;

}



nav .navbar-nav li a{
  color: black !important;
  font-weight: 300;
  font-size: 30px;
}


.nav-item,.nav-link{
  font-size: 18px;
  color: black !important;
}

.dropdown-item{
  height: 45px;
}
/*SIDEBAR*/

.col{

  display: flex;
 

}



.header{
  height: 50%;
  width: 100%;
  
}

.sidebar .nav{
  padding: 0px !important;
  font-weight: bold;

}

.sidebar ul li a:hover{
  background-color: white;
}

.sidebar{
  background-color: gold;
  height: 50%;
  align-items: center;
}



.nav {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .nav {
    width:100%;
  }
}

.par{
  font-family: "Helvetica";
  font-weight: 200;
}











ul {

  list-style-type: none;
  margin: auto;
 

}

ul.nav li.dropdown:hover > ul.dropdown-menu {
  display: block;    
}

li {
  float: left;

}

li a {
  display: block;
  color: white;
  text-align: center;
 
  text-decoration: none;
}

li a:hover {
  background-color: #111111;
}

ul#bullets{
  list-style-type:disc;
  padding-left: 20px;


 
}




.lst{

  text-align: center;
  padding: 0px;

  width: 100%;
}




/*BODY*/
.main{

  width: 100%;
  background-color: gold;
  /*background-color: orange;*/
  height: 100vh;

  overflow: auto;
  /*margin: auto;*/

  /*
  justify-content: center;
  display: flex;
  */

}

.d-flex{
  background-color: gold;
}

.container {
  /*background-color: red;*/
  width: 100%;
  height: 100%;



  display: inline-block;



  /*
  display: flex;
  justify-content: center;
 */
  
}






.subcontainer {
  /*background-color: pink;*/
  width: 100%;
  height: 100%;



  /*
  display: flex;
  justify-content: center;
  */
  
}
/* removing comma makes it AND opertor. with comma is OR */
.row#subcontainer-row{
  display: flex;
  flex: 80%;
}

.row#subcontainer-row {
   /*background-color: blue;*/
   height: 80%;
   width: 80%;
   margin: auto;
   /*flex: 80%;*/
   /*display: flex;*/
}

.row#subcontainer-rowA {
  /*background-color: blue;*/
  height: 80%;
  width: 80%;
  margin: auto;
  /*flex: 80%;*/
  /*display: flex;*/
}


.column1 {
 flex: 50%;
}

.column2 {
 flex: 50%;

}

.photo {
  margin-left: auto;
  margin-right: auto;

}



.logo{

  margin-top: 10px;
  width:180px;

  /*width:100%;*/
  /*height:300px;
  font-family:"Open Sans";
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  margin-top: 20px;*/

}

.photo{

  margin-top: 10px;
  margin-left:0px;
  width:300px;
}

.heading{

  font-family: "Protest Guerrilla";
  font-size: 50px;
  line-height: 50px;
  font-weight: 900;
  margin: auto;
  display: block;

}

.heading1{
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  margin: auto;
  display: block;

}



.heading2{

  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  margin-top: 10px;



}

.heading3{

  font-size: 30px;
  line-height: 35px;
  font-weight: 500;

}

.headingA{
  text-align: left;
  font-size: 50px;
  line-height: 50px;
  font-weight: 800;
  margin: auto;
  display: block;

}

.headingB{

 text-align: left;
  font-size: 45px;
  line-height: 45px;
  font-weight: 300;
  margin: auto;
  display: block;

}

h5{
  font-weight: 900;
}

.txt{

  width:70%;
  height: 100%;
  float:right;

  /*margin: auto;*/
  margin-right: 10px;


}



#email{
  color:gray;
  background-color: transparent;
  text-decoration: none;
  font-size:20px;
  line-height: 20px;
}

@media only screen and (max-width: 600px) {
  .txt {
    width:100%;
    margin: auto;
  }
}

.mission{
  position: absolute;
  border: 1px solid red;
  bottom: 0;
}


.download{
  margin: auto;
  text-align: center;
  font-weight: 300;
}



@media only screen and (max-width: 600px) {
  .container-fluid {

  }
}